<div fxLayoutAlign="center center" fxLayout="column" style="width: 100%; height: 100%"
     *ngIf="!setupDone  || !animationDone else showApp">
  <div [@slideInOut] (@slideInOut.done)="animationfinished()">
    <img src="assets/icon/CLED-Logo.png" alt="kein Logo"/>
  </div>
  <div>
    <mat-progress-spinner [ngStyle]="{visibility: animationDone ? 'visible' : 'hidden'}"
                          [mode]="'indeterminate'"></mat-progress-spinner>
  </div>
</div>

<ng-template #showApp>
  <mat-toolbar color="primary" *ngIf="displayName">
    <div fxLayout="row" fxLayoutAlign="space-between" style="width:100%">
      <div (click)="navigateDashboard()">Prüfungsbewertungen</div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <a mat-button>
            <mat-icon>
              person
            </mat-icon>
              {{displayName}}
        </a>
      </div>
    </div>
  </mat-toolbar>
  <router-outlet></router-outlet>
</ng-template>
