import {Component, OnDestroy, OnInit} from '@angular/core';

import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';
import {AngularFireAuth} from '@angular/fire/auth';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  warnMessages: string;
  username: string;
  password: string;
  passwordResetShown = false;

  constructor(public afAuth: AngularFireAuth, public dialogRef: MatDialogRef<LoginComponent>,
              private router: Router) {
  }

  ngOnInit() {
  }


  ngOnDestroy(): void {
  }

  closeDialog(result: any) {
    this.dialogRef.close(result);
  }

  login() {
    this.afAuth.signInWithEmailAndPassword(this.username, this.password).then(userCredentials => {
      this.closeDialog(true);
    }).catch(error => {
      switch (error.code) {
        case 'auth/wrong-password': {
          if (this.warnMessages !== 'Benutzername oder Passwort ist falsch') {
            this.warnMessages = 'Benutzername oder Passwort ist falsch';
          }
          break;
        }
        case 'auth/argument-error': {
          if (this.warnMessages !== 'Benutzername oder Passwort ist falsch') {
            this.warnMessages = 'Benutzername oder Passwort ist falsch';
          }
          break;
        }
        case 'auth/user-not-found': {
          if (this.warnMessages !== 'Es existiert kein Benutzer mit diesen Daten.') {
            this.warnMessages = 'Es existiert kein Benutzer mit diesen Daten.';
          }
          break;
        }
        case 'auth/too-many-requests': {
          if (this.warnMessages !== 'Ihr Account wurde für 5 minuten deaktiviert, da es zu viele fehlerhafte Loginversuche gab.') {
            this.warnMessages = 'Ihr Account wurde für 5 minuten deaktiviert, da es zu viele fehlerhafte Loginversuche gab.';
          }
          break;
        }
      }
      setTimeout(() => this.warnMessages = '', 5000);
      console.log(error);
    });
  }

  showPasswordReset() {
    this.passwordResetShown = true;
  }
}
