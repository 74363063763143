export function toStringDate(date: Date) {
  return date.getFullYear() + '-' +
    (date.getMonth() + 1) + '-' + date.getDate();
}

export function toJSDate(date: string): Date {
  const dateparts = date.split('-');
  const tmpDate = new Date();
  tmpDate.setFullYear(+dateparts[0], (+dateparts[1] - 1), +dateparts[2]);
  return new Date(tmpDate);
}

/*
  * parses a string in format yyyy-mm-dd as date and returns the corresponding weekday string
  */
export function getWeekDayOfDateString(date: string): string {

  return getWeekDayOfDate(toJSDate(date));
}

export function getWeekDayOfDate(date: Date): string {
  const weekDayNumber = date.getDay();
  switch (weekDayNumber) {
    case 0:
      return 'sunday';
      break;
    case 1:
      return 'monday';
      break;
    case 2:
      return 'tuesday';
      break;
    case 3:
      return 'wednesday';
      break;
    case 4:
      return 'thursday';
      break;
    case 5:
      return 'friday';
      break;
    case 6:
      return 'saturday';
      break;
  }
}

export function getAgeByBirthdayString(studentBirthday: string): number {
  const birthdate = toJSDate(studentBirthday);
  const today = new Date();
  let age = today.getFullYear() - birthdate.getFullYear();
  const m = today.getMonth() - birthdate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
    age--;
  }
  return age;
}
