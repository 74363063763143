// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const firebaseConfig = {
  apiKey: 'AIzaSyDDA_JNZLdKr9xuoZQVJqVd_2mi2QMtxHM',
  authDomain: 'clevereducate-for-schools-dev.firebaseapp.com',
  databaseURL: 'https://clevereducate-for-schools-dev.firebaseio.com',
  projectId: 'clevereducate-for-schools-dev',
  storageBucket: 'clevereducate-for-schools-dev.appspot.com',
  messagingSenderId: '965987068857',
  appId: '1:965987068857:web:d6f0f73275c54e2d6de468',
  measurementId: 'G-WN89XDYEF2'
};

/*const firebaseConfig = {
  apiKey: 'AIzaSyCHIS2OW0GFAEylrUGjS-b4a0PcITIWo2U',
  authDomain: 'rrbk-clevereducate-for-schools.firebaseapp.com',
  databaseURL: 'https://rrbk-clevereducate-for-schools.firebaseio.com',
  projectId: 'rrbk-clevereducate-for-schools',
  storageBucket: 'rrbk-clevereducate-for-schools.appspot.com',
  messagingSenderId: '517224127529',
  appId: '1:517224127529:web:8a1c6a30cd7fd95a6c5038',
  measurementId: 'G-DK9E07CWR5'
};*/
export const environment = {
  production: false,
  firebase: firebaseConfig
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
