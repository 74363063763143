<!--<app-password-reset id="passwordReset" *ngIf="passwordResetShown"></app-password-reset>-->
<div *ngIf="!passwordResetShown">
<div  mat-dialog-title>
  <button class="closeButton"mat-icon-button [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
  <h4>Login</h4>
</div>
<mat-dialog-content  fxLayout="column" fxLayoutAlign="center center">
  <div>
    <div class="alert alert-warning" *ngIf="warnMessages" role="alert">
      <mat-error *ngIf="warnMessages">{{warnMessages}}</mat-error>
    </div>
  </div>
  <mat-form-field style="width: 250px">
    <input matInput type="text" name="username" id="username" [(ngModel)]="username" placeholder="Benutzername"
           required minlength="0" maxlength="40" >
  </mat-form-field >
  <mat-form-field style="width: 250px">
    <input type="password" matInput name="password" id="password" [(ngModel)]="password" placeholder="Passwort"
           required minlength="0" maxlength="40" (keyup.enter)="login()">
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions  fxLayout="row" fxLayoutAlign="center center">
  <button mat-raised-button color="primary" type="submit" (click)="login()">Login</button>
  <button mat-button (click)="showPasswordReset()">Passwort vergessen?</button>
</mat-dialog-actions>
</div>
