import { Injectable } from '@angular/core';
import {ExamGrading} from '../model/ExamGrading';
import {from, Observable} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {convertDocument, convertSnaps} from '../../util/ResponseConverter';
import {map} from 'rxjs/operators';
import Timestamp = firestore.Timestamp;
import {firestore} from 'firebase/app';
@Injectable({
  providedIn: 'root'
})
export class ExamGradingService {

  constructor(private db: AngularFirestore) { }

  getExamGradingsWithId(gradingId: string): Observable<ExamGrading> {
    return this.db.collection('examGradings').doc(gradingId).get()
      .pipe( map( snaps => convertDocument(snaps)));
  }

  snapshotAllExamGradings(): Observable<ExamGrading[]> {
    return this.db.collection('examGradings').snapshotChanges()
      .pipe( map( snaps => convertSnaps(snaps)));
  }

  save(examGrading: ExamGrading): Observable<any> {
    if (examGrading.id === undefined) {
      examGrading.id = this.db.createId();
      examGrading.created = Timestamp.now();
      return from(this.db.doc('examGradings/' + examGrading.id).set(examGrading));
    } else {
      return from(this.db.doc('examGradings/' + examGrading.id).set(examGrading, {merge: true}));
    }
  }

  deleteExamGrading(row: ExamGrading) {
    this.db.collection('examGradings').doc(row.id).delete();
  }
}
