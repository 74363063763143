import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ExamGrading} from '../model/ExamGrading';
import {ExamGradingService} from '../services/exam-grading.service';
import {MatSort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {toJSDate} from '../../util/DateHelper';
import {map} from 'rxjs/operators';

interface TableRow {
  id: string;
  subject: string;
  date: string;
  topic: string;
  studentName: string;
  schoolclass: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource: MatTableDataSource<TableRow>;
  displayedColumns = ['index', 'topic', 'studentName', 'date', 'subject', 'schoolclass', 'delete'];
  private gradingSubscription$: Subscription;
  private examGradings: ExamGrading[];
  constructor(private examGradingService: ExamGradingService, private router: Router) {
  }

  ngOnInit(): void {
    this.gradingSubscription$ = this.examGradingService.snapshotAllExamGradings()
      .pipe(map(examGradings => {
        this.examGradings = examGradings;
        return this.toTableRows(examGradings);
      }))
      .subscribe(examGradings => {
          this.dataSource = new MatTableDataSource<TableRow>(examGradings);
          this.dataSource.sort = this.sort;
        }
      );
  }

  private toTableRows(examGradings: ExamGrading[]): TableRow[] {
    const ret = [] as TableRow[];
    console.log(examGradings);
    examGradings.forEach(examGrading => {
      if (!examGrading.participation || examGrading.participation.length === 0) {
        ret.push({
          id: examGrading.id,
          subject: examGrading.subject,
          date: examGrading.date,
          topic: examGrading.topic,
          studentName: '',
          schoolclass: examGrading.schoolClass
        } as TableRow);
      } else {
        examGrading.participation.forEach(e => {
          ret.push({
            id: examGrading.id,
            subject: examGrading.subject,
            date: examGrading.date,
            topic: examGrading.topic,
            studentName: e.name,
            schoolclass: examGrading.schoolClass
          } as TableRow);
        });
      }
    });
    console.log(ret);
    return ret;
  }

  applyFilter(value: string) {
    this.dataSource.filter = value.trim().toLowerCase();
  }

  selectRow(id: string) {
    this.router.navigate(['/exams/' + id]);
  }

  ngOnDestroy(): void {
    if (this.gradingSubscription$) {
      this.gradingSubscription$.unsubscribe();
    }
  }

  examDate(exam: ExamGrading) {
    if (exam?.date) {
      return toJSDate(exam.date).toLocaleDateString();
    }
  }

  deleteRow(exam: TableRow) {
    const row = this.examGradings.find(e => e.id === exam.id);
    if (row) {
      if (!row.participation || row.participation.length === 0) {
        this.examGradingService.deleteExamGrading(row);
      } else {
        const indexPart = row.participation.findIndex(x => x.name === exam.studentName);
        if (indexPart !== -1) {
          row.participation.splice(indexPart, 1);
          if (!row.participation || row.participation.length === 0) {
            this.examGradingService.deleteExamGrading(row);
          } else {
            this.examGradingService.save(row);
          }
        }
      }
    }
  }
}
