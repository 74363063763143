<div fxLayout="column" fxLayoutAlign="center">
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-around center">
    <button mat-raised-button color="primary" routerLink="/exams/new">Neu anlegen</button>
    <mat-form-field style="width: 20em">
      <input matInput (keyup)="applyFilter($event.target.value)" #filter placeholder="Filter">
      <button mat-button *ngIf="filter.value" matSuffix mat-icon-button aria-label="Clear"
              (click)="filter.value='';applyFilter('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>


    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> #</th>
      <td mat-cell *matCellDef="let exam; let i = index" (click)="selectRow(exam.id)"> {{i + 1}} </td>
    </ng-container>

    <ng-container matColumnDef="studentName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Teilnehmer</th>
      <td mat-cell *matCellDef="let exam" (click)="selectRow(exam.id)">{{exam.studentName}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="subject">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fach</th>
      <td mat-cell *matCellDef="let exam" (click)="selectRow(exam.id)"> {{exam.subject}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Datum</th>
      <td mat-cell *matCellDef="let exam" (click)="selectRow(exam.id)"> {{examDate(exam)}}</td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="topic">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Thema</th>
      <td mat-cell *matCellDef="let exam" (click)="selectRow(exam.id)"> {{exam.topic}} </td>
    </ng-container>

    <ng-container matColumnDef="schoolclass">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Klasse</th>
      <td mat-cell *matCellDef="let exam" (click)="selectRow(exam.id)"> {{exam.schoolclass}} </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Löschen</th>
      <td mat-cell *matCellDef="let exam; let i = index"> <button mat-icon-button color="warn" (click)="deleteRow(exam)">
        <mat-icon>
          delete
        </mat-icon>
      </button> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
