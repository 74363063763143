<div fxLayout="column">
  <div style="margin-left: 5%">
    <h2>Prüfungsinformationen</h2>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="25px">
        <mat-form-field>
          <mat-label>Schulklasse</mat-label>
          <input matInput type="text" [(ngModel)]="examGrading.schoolClass">
          <button mat-button *ngIf="examGrading.schoolClass" matSuffix mat-icon-button aria-label="Clear"
                  (click)="examGrading.schoolClass=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Prüfungsdatum</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="selectedDate" (dateChange)="changeDate()">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="25px">
        <mat-form-field>
          <mat-label>Fach</mat-label>
          <input matInput type="text" [(ngModel)]="examGrading.subject">
          <button mat-button *ngIf="examGrading.subject" matSuffix mat-icon-button aria-label="Clear"
                  (click)="examGrading.subject=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      <mat-form-field style="width: 250px">
        <mat-label>Thema</mat-label>
        <textarea name="topic" matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  [(ngModel)]="examGrading.topic"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div style="margin-left: 5%">
    <h2>Teilnehmer</h2>
    <div>
      <mat-form-field>
        <mat-label>Teilnehmer hinzufügen</mat-label>
        <input matInput type="text" [(ngModel)]="studentName" (keydown.enter)="addParticipant()">
        <button mat-button *ngIf="studentName" matSuffix mat-icon-button aria-label="Clear" (click)="studentName=''">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <button mat-icon-button color="primary" (click)="addParticipant()">
        <mat-icon>
          add_box
        </mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <mat-tab-group *ngIf="examGrading?.participation?.length > 0 else noParticipants">
    <mat-tab *ngFor="let p of examGrading.participation; let i = index" label="{{p.name}}">
      <div fxLayout="column" fxLayoutGap="25px">
        <div><!-- this is a spacing diff --></div>
        <div fxLayout="row" fxLayoutAlign="space-around">
          <div fxLayout="column">
            <h2>Punktzahl Prüfungsteil 1</h2>
            <div>Inhalt: {{p.examPartOne.subPointsFirst}} / 10</div>
            <div>Darstellungsleistung: {{getTalkPart(p.examPartOne)}} / 15</div>
            <div><b>Summe: {{p.examPartOne.subPointsFirst +getTalkPart(p.examPartOne)}} / 25</b></div>
          </div>
          <div fxLayout="column">
            <h2>Punktzahl Prüfungsteil 2</h2>
            <div>Inhalt: {{p.examPartTwo.subPointsFirst}} / 10</div>
            <div>Darstellungsleistung: {{getTalkPart(p.examPartTwo)}} / 15</div>
            <div><b>Summe: {{p.examPartTwo.subPointsFirst + getTalkPart(p.examPartTwo) }} / 25</b></div>
          </div>
          <div fxLayout="column">
            <h2>Gesamtpunktzahl</h2>
            <div><b>Summe: {{getFullSum(p)}} / 50</b></div>
            <div><b>Notenpunkte: {{getGradePoints(p)}}</b></div>
            <div><b>Note: {{getGradeName(p)}}</b></div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div>
          <h1> Prüfungsteil 1: Zusammenhängendes Sprechen</h1>
          <div fxLayout="row" fxLayoutAlign="space-around">
            <div>
              <h4>Inhaltliche Leistung / Aufgabenerfüllung</h4>
              <mat-radio-group [(ngModel)]="p.examPartOne.subPointsFirst" fxLayout="column" fxLayoutGap="10px">
                <mat-radio-button *ngFor="let s of successOptions" [value]="s.value" matTooltip="{{s.tooltip}}">
                  {{s.value}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div>
              <h4>Kommunikative Strategie / Präsentationskompetenz</h4>
              <mat-radio-group [(ngModel)]="p.examPartOne.subPointsSecond" fxLayout="column" fxLayoutGap="10px">
                <mat-radio-button *ngFor="let s of communicationPoints" [value]="s.value" matTooltip="{{s.tooltip}}">
                  {{s.value}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div>
              <h4>Aussprache / Infonation</h4>
              <mat-radio-group [(ngModel)]="p.examPartOne.subPointsThird" fxLayout="column" fxLayoutGap="10px">
                <mat-radio-button *ngFor="let s of talkingPoints" [value]="s.value" matTooltip="{{s.tooltip}}">
                  {{s.value}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div>
              <h4>Wortebene</h4>
              <mat-radio-group [(ngModel)]="p.examPartOne.subPointsFourth" fxLayout="column" fxLayoutGap="10px">
                <mat-radio-button *ngFor="let s of wordPoints" [value]="s.value" matTooltip="{{s.tooltip}}">
                  {{s.value}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div>
              <h4>Satzebene</h4>
              <mat-radio-group [(ngModel)]="p.examPartOne.subPointsFifth" fxLayout="column"  fxLayoutGap="10px">
                <mat-radio-button *ngFor="let s of sentencePoints" [value]="s.value" matTooltip="{{s.tooltip}}">
                  {{s.value}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div>
          <h1> Prüfungsteil 2: An Gesprächen teilnehmen</h1>
          <div fxLayout="row" fxLayoutAlign="space-around">
            <div>
              <h4>Inhaltliche Leistung / Aufgabenerfüllung</h4>
              <mat-radio-group [(ngModel)]="p.examPartTwo.subPointsFirst" fxLayout="column" fxLayoutGap="10px">
                <mat-radio-button *ngFor="let s of successOptions" [value]="s.value" matTooltip="{{s.tooltip}}">
                  {{s.value}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div>
              <h4>Kommunikative Strategie / Präsentationskompetenz</h4>
              <mat-radio-group [(ngModel)]="p.examPartTwo.subPointsSecond" fxLayout="column" fxLayoutGap="10px">
                <mat-radio-button *ngFor="let s of communicationPoints" [value]="s.value" matTooltip="{{s.tooltip}}">
                  {{s.value}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div>
              <h4>Aussprache / Infonation</h4>
              <mat-radio-group [(ngModel)]="p.examPartTwo.subPointsThird" fxLayout="column" fxLayoutGap="10px">
                <mat-radio-button *ngFor="let s of talkingPoints" [value]="s.value" matTooltip="{{s.tooltip}}">
                  {{s.value}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div>
              <h4>Wortebene</h4>
              <mat-radio-group [(ngModel)]="p.examPartTwo.subPointsFourth" fxLayout="column" fxLayoutGap="10px">
                <mat-radio-button *ngFor="let s of wordPoints" [value]="s.value" matTooltip="{{s.tooltip}}">
                  {{s.value}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div>
              <h4>Satzebene</h4>
              <mat-radio-group [(ngModel)]="p.examPartTwo.subPointsFifth" fxLayout="column" fxLayoutGap="10px">
                <mat-radio-button *ngFor="let s of sentencePoints" [value]="s.value" matTooltip="{{s.tooltip}}">
                  {{s.value}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>

        <div fxLayout="row">
          <button mat-raised-button color="primary" (click)="save()">Speichern</button>
          <button mat-raised-button color="warn"
                  (click)="examGrading.participation.splice(i,1)">{{p.name}} entfernen
          </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <ng-template #noParticipants>
    <div fxLayout="column" fxLayoutGap="25px" fxLayoutAlign="start center">
      <div>
        Keine Teilnehmer hinzugefügt
      </div>
      <button mat-raised-button color="primary" (click)="save()">Speichern</button>
    </div>

  </ng-template>
</div>
