import {Component, OnInit} from '@angular/core';
import {ExamGrading, ExamParticipation} from '../model/ExamGrading';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ExamGradingService} from '../services/exam-grading.service';
import {toJSDate, toStringDate} from '../../util/DateHelper';

@Component({
  selector: 'app-exam-detail',
  templateUrl: './exam-detail.component.html',
  styleUrls: ['./exam-detail.component.css']
})
export class ExamDetailComponent implements OnInit {
  examGrading: ExamGrading;
  studentName: string;
  successOptions = [{
    value: 10,
    tooltip: null
  }, {
    value: 9,
    tooltip: 'Die Aufgaben werden ausführlich und präzise erfüllt, wobei tiefer gehende differenzierte Kenntnisse deutlich werden'
  }, {
    value: 8,
    tooltip: null
  }, {
    value: 7,
    tooltip: 'Es werden durchgängig sachgerechte und aufgabengemäße Gedanken geliefert, die den behandelten Themenbereich auch erweitern können'
  }, {
    value: 6,
    tooltip: null
  }, {
    value: 5,
    tooltip: 'Die Ausführungen sind hinsichtlich Plausibilität und Argumentation nachvollziehbar. Die entwickelten Ideen beziehen sich auf die Aufgaben/Dokumente und beruhen auf einem angemessenen Maß an Sachwissen.'
  }, {
    value: 4,
    tooltip: null
  }, {
    value: 3,
    tooltip: 'Nur wenige der geforderten Aspekte bezüglich der Aufgaben werden erkannt und richtig angegeben. Die Ausführungen beziehen sich nur eingeschränkt auf die Aufgaben und sind manchmal unklar.'
  }, {
    value: 2,
    tooltip: null
  }, {
    value: 1,
    tooltip: 'Die Ausführungen zeichen, dass die Aufgabenstellung / die Vorlange nicht verstanden wurden. Auch durch zusätzliche Impulse werden nur lückenhafte Beiträge geliefert.'
  }, {
    value: 0,
    tooltip: null
  }];
  selectedDate: Date;
  communicationPoints = [{
    value: 4,
    tooltip: 'gedanklich stringent; effizient; klar und flüssig; weitgehend freier Vortrag'
  }, {
    value: 3,
    tooltip: 'vorwiegend kohärent und strukturiert; der Darstellungssituation angemesse; in der Regel sicher und flüssig'
  }, {
    value: 2,
    tooltip: 'grundlegende Struktur erkennbar, z.T. verkürzend und/oder weitschweifend; nicht durchgehend flüssig'
  }, {
    value: 1,
    tooltip: 'sehr unselbstständig, unstrukturiert, Zusammenhang kaum zu erkennen; stockend und unsicher'
  },
    {
      value: 0,
      tooltip: null
    }];
  talkingPoints = [{
    value: 3,
    tooltip: 'klare, korrekte Aussprache und Information; Betonung / Infonation wird kommunikativ geschickt eingesetzt'
  }, {
    value: 2,
    tooltip: 'Im Allgemeinen klare und korrekte Aussprache und Infonation'
  }, {
    value: 1,
    tooltip: 'Mangel an Deutlichkeit und Klarheit, Aussprachefehler beeinträchtigen Verständnis'
  }, {
    value: 0,
    tooltip: null
  }];
  wordPoints = [{
    value: 4,
    tooltip: 'präziser, differenzierter und variabler Wortschatz'
  }, {
    value: 3,
    tooltip: 'überwiegend treffende Formulierungen; z.T. idomatische Wendungen'
  }, {
    value: 2,
    tooltip: 'einfacher, aber angemessener Wortschatz; Überwindung von Schwierigkeiten durch Umschreibungen'
  }, {
    value: 1,
    tooltip: 'sehr einfacher und lückenhafter Wortschatz; häufige Wiederholungen'
  }, {
    value: 0,
    tooltip: null
  }];
  sentencePoints = [{
    value: 4,
    tooltip: 'breites und differenziertes Repertoire an Strukturen; nahezu fehlerfrei'
  }, {
    value: 3,
    tooltip: 'gefestigtes Repertoire grundlegender Strukturen; weitgehend frei von Verstößén; Selbstkorrektur vorhanden'
  }, {
    value: 2,
    tooltip: 'Repertoire grundlegender Strukturen verfügbar, z.T. fehlerhaft'
  }, {
    value: 1,
    tooltip: 'auch grundlegende Strukturen nicht durchgängig verfügbar'
  }, {
    value: 0,
    tooltip: null
  }
  ];

  constructor(private route: ActivatedRoute,
              private examGradingService: ExamGradingService) {
  }

  ngOnInit(): void {
    this.examGrading = this.route.snapshot.data.examGrading;
    if (this.examGrading.date) {
      this.selectedDate = toJSDate(this.examGrading.date);
    }
    console.log(this.examGrading);
  }

  addParticipant() {
    if (!this.examGrading.participation) {
      this.examGrading.participation = new Array<ExamParticipation>();
    }
    this.examGrading.participation.push({
      name: this.studentName,
      examPartOne: {
        subPointsFirst: 0,
        subPointsSecond: 0,
        subPointsThird: 0,
        subPointsFourth: 0,
        subPointsFifth: 0
      },
      examPartTwo: {
        subPointsFirst: 0,
        subPointsSecond: 0,
        subPointsThird: 0,
        subPointsFourth: 0,
        subPointsFifth: 0
      }
    });
    this.studentName = '';
  }


  save() {
    console.log(this.examGrading);
    this.examGradingService.save(this.examGrading);
  }

  changeDate() {
    this.examGrading.date = toStringDate(this.selectedDate);
  }

  compareFn(s1: any, s2: any): boolean {
    return s1 && s2 ? s1.value === s2.value : s1 === s2;
  }


  getFullSum(p: ExamParticipation): number {
    return this.getFullPartOne(p) + this.getFullPartTwo(p);
  }

  getTalkPart(p: {
    subPointsFirst: number; subPointsSecond: number;
    subPointsThird: number; subPointsFourth: number; subPointsFifth: number
  }): number {
    return p.subPointsSecond + p.subPointsThird + p.subPointsFourth + p.subPointsFifth;
  }

  getFullPartOne(p: ExamParticipation): number {
    return p.examPartOne.subPointsFirst + this.getTalkPart(p.examPartOne);
  }


  getFullPartTwo(p: ExamParticipation) {
    return p.examPartTwo.subPointsFirst + this.getTalkPart(p.examPartTwo);
  }

  getGradePoints(p: ExamParticipation): number {
    const sum = this.getFullSum(p);
    if (sum >= 48) {
      return 15;
    } else if (sum < 48 && sum >= 45) {
      return 14;
    } else if (sum < 45 && sum >= 43) {
      return 13;
    } else if (sum < 43 && sum >= 40) {
      return 12;
    } else if (sum < 40 && sum >= 38) {
      return 11;
    } else if (sum < 38 && sum >= 35) {
      return 10;
    } else if (sum < 35 && sum >= 33) {
      return 9;
    } else if (sum < 33 && sum >= 30) {
      return 8;
    } else if (sum < 30 && sum >= 28) {
      return 7;
    } else if (sum < 28 && sum >= 25) {
      return 6;
    } else if (sum < 25 && sum >= 23) {
      return 5;
    } else if (sum < 23 && sum >= 20) {
      return 4;
    } else if (sum < 20 && sum >= 17) {
      return 3;
    } else if (sum < 17 && sum >= 14) {
      return 2;
    } else if (sum < 14 && sum >= 10) {
      return 1;
    } else if (sum < 10 && sum >= 0) {
      return 0;
    }
  }


  getGradeName(p: ExamParticipation) {
    const points = this.getGradePoints(p);
    switch (points) {
      case 0:
        return 'ungenügend';
      case 1:
        return 'mangelhaft -';
      case 2:
        return 'mangelhaft';
      case 3:
        return 'mangelhaft +';
      case 4:
        return 'ausreichend -';
      case 5:
        return 'ausreichend';
      case 6:
        return 'ausreichend +';
      case 7:
        return 'befriedigend -';
      case 8:
        return 'befriedigend';
      case 9:
        return 'befriedigend +';
      case 10:
        return 'gut -';
      case 11:
        return 'gut';
      case 12:
        return 'gut +';
      case 13:
        return 'sehr gut -';
      case 14:
        return 'sehr gut';
      case 15:
        return 'sehr gut +';
    }
  }
}
