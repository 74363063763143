import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {ExamGradingService} from '../services/exam-grading.service';
import {ExamGrading} from '../model/ExamGrading';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExamGradingResolver implements Resolve<ExamGrading> {
  constructor(private examGradingService: ExamGradingService) {
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ExamGrading> | Promise<ExamGrading> | ExamGrading {
    const gradingId = route.paramMap.get('id');
    if (gradingId === 'new') {
      return of({
      } as ExamGrading);
    } else {
      return this.examGradingService.getExamGradingsWithId(gradingId);
    }
  }
}
