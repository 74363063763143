import {Component, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';

@Component({
  selector: 'app-startup',
  templateUrl: './startup.component.html',
  styleUrls: ['./startup.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('800ms ease-in', style({transform: 'translateX(0%)'})),
      ]),
    ])
  ]
})
export class StartupComponent implements OnInit {
  animationDone: boolean = false;
  setupDone = false;
  displayName: string;

  constructor(public afAuth: AngularFireAuth, private router: Router) {
  }

  ngOnInit(): void {
    this.afAuth.user
      .subscribe(u => {
        this.displayName = u !== null ? u.displayName : null;
        this.setupDone = true;
        if (u) {
          this.navigateDashboard();
        }
      });
  }

  animationfinished() {
    this.animationDone = true;
  }

  navigateDashboard() {
    this.router.navigate(['/dashboard']);
  }

  signOut() {
    this.afAuth.signOut();
  }
}
