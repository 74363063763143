import {Component, OnDestroy, OnInit} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';

import {ActivatedRoute, Router} from '@angular/router';
import {LoginComponent} from '../../cross/login/login.component';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit, OnDestroy {

  autoLogin = false;

  constructor(public afAuth: AngularFireAuth, public dialog: MatDialog, private route: ActivatedRoute, private router: Router,) {
  }

  ngOnInit() {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.autoLogin = true;
      } else {
        this.autoLogin = false;
      }
    });
  }

  login() {
  this.openlogin();
  }

   openlogin() {
   if (this.autoLogin) {
      this.redirectAuthenticated();
    } else {
      const dialogRef = this.dialog.open(LoginComponent, {
        id: 'login-dialog',
        height: '500px',
        width: '500px',
      });
      dialogRef.afterClosed().subscribe();
    }
  }

  private redirectAuthenticated() {
    this.router.navigate(['/dashboard']);
  }

  ngOnDestroy(): void {

  }
}
