import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {LandingPageComponent} from './main/landing-page/landing-page.component';
import {DashboardComponent} from './main/dashboard/dashboard.component';
import {ExamDetailComponent} from './main/exam-detail/exam-detail.component';
import {ExamGradingResolver} from './main/resolver/ExamGradingResolver';


const routes: Routes = [
  {path: '', component: LandingPageComponent},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'exams/:id', component: ExamDetailComponent,
  resolve: {
    examGrading: ExamGradingResolver
  }}
];
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ]
})
export class AppRoutingModule { }
